import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Grid from '@material-ui/core/Grid';

import methodology from '../../img/about/methodology.jpg'
import social from '../../img/about/social.jpg'
import team1 from '../../img/about/team1.jpg'
import team2 from '../../img/about/team2.jpg'
import team3 from '../../img/about/team3.jpg'
import team4 from '../../img/about/team4.jpg'

export const AboutPageTemplate = ({  }) => {
  // const PageContent = contentComponent || Content

  return (
    <section className="section-about">

      {/* Section 1 */}
      <Grid container
        direction="row"
        justify="center"
        id="theatreLedMethodology"
        className="mb-px-2"
  
      >
        <Grid item xs={12} sm={12} md={6} >
            <h1 className="main-title mt-4 mr-2 text-right mb-mb-0 mb-mx-0 mb-text-center">All the world’s</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <h1 className="main-title mb-mt-0 mb-mb-0  mb-mx-0 mb-text-center">a stage</h1>
          <h2 className="main-sub-title mb-text-center">Theatre-led methodology</h2>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
  
      >
        <Grid item xs={12} sm={12} md={6} >
            <img src={methodology} className="about-img" alt="The skillset of a performer"/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="mb-px-2" >
          <p className="page-description mb-mt-0">The skillset of a performer translates to any profession. Whether it’s improvisation, collaboration or communication, students employ a diverse range of stage techniques in their everyday experiences. </p>
          <p className="page-description mt-2 mb-mt-0">In a changing world, students at Sesquipedalian will be better equipped to communicate purposefully, think creatively and critically, build emotional intelligence and manage interpersonal relationships with proficiency and care.</p>
        </Grid>
      </Grid>

      {/* Section 2 */}
      <Grid container
        direction="row"
        justify="center"
        className="mt-3 mb-mt-2 mb-px-1"
        id="socialLearning"
      >

        <Grid item xs={12} sm={12} md={6}>
          <h1 className="main-title mt-4 mr-2 mb-mt-1 mb-mx-0 text-right mb-text-center">Getting into</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <h1 className="main-title mb-text-center mb-mb-0 mb-mx-0">character</h1>
            <h2 className="main-sub-title mb-text-center">Social and Emotional Learning (SEL)</h2>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} className="mb-px-2" >
          <p className="page-description mt-2 text-right mb-mt-1 mb-text-left">We believe great performance comes from authenticity. At Sesquipedalian, we design Social and Emotional Learning (SEL) environments to give your child a sense of empathy, social awareness and interpersonal acuity by simultaneously stimulating thinking, feeling and doing.</p>
        </Grid>

        <Grid item xs={12} sm={12} md={6} >
          <img src={social} className="about-img" alt="We believe great performance"/>
        </Grid>
      </Grid>

      {/* Section 3 */}
      <Grid container
        direction="row"
        justify="center"
        className="mt-3 mb-mt-2 mb-px-1"
        id="team"
      >
        <Grid item xs={12} sm={12} md={4} >
            <h1 className="main-title mt-4 mr-2 text-right mb-mt-0 mb-mb-0 mb-mx-0 mb-text-center">Setting</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="mb-mt-0" >
          <h1 className="main-title mb-mb-0 mb-text-center">the stage</h1>
          <h2 className="main-sub-title mb-text-center">The team behind Sesquipedalian</h2>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
  
      >
      <Grid item xs={12} sm={12} md={4} >
            <img src={team1} className="about-img about-img-portrait mb-img-height-auto" alt="Amanda Paiva"/>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="mb-px-2" >
          <p className="page-description mt-2 mb-mt-0"><b><i>Amanda Paiva </i></b> has always been passionate about      
				theatre as a tool to enable young learners to express 
				themselves creatively and effectively.
				Over the course of her prolific career, she’s earned
				qualifications such as the Associate Teachers
 				Certification from Trinity College London, a diploma
          in  Education and Certification in ESL training.
 						She has taught at Colombo International School since 
 					2009 and founded Spotlight Young Actors’ Studio
   					in 2012.</p>
          <p className="page-description mt-2 mb-mt-1">Following this success, Amanda launched 
					           Sesquipedalian, a holistic, theatre-led academic 
                    curriculum to bring performing arts from the stage to the 
		         				classroom.</p>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className="mb-flex-col-reverse"
      >

        <Grid item xs={12} sm={12} md={8} className="mb-px-2" >
          <p className="page-description mt-2 text-left mb-text-left mb-mt-0"><b><i>Wendy Devaraj </i></b>is an educator and artist whose work 
          focuses on instructing young learners during early childhood. She holds a professional diploma in advanced teaching and the Cambridge TKT certification. 
          With teaching experience of over 5 years, Wendy believes in providing a learning experience that taps into students' passion so they can form deeper connections with the curriculum.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={4} >
            <img src={team2} className="about-img about-img-portrait mb-img-height-auto" alt="Wendy Devaraj"/>
        </Grid>
      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className="mb-mb-2"
      >
        <Grid item xs={12} sm={12} md={4} >
          <img src={team3} className="about-img about-img-portrait mb-img-height-auto"  alt="Hilarine Fernando"/>
        </Grid>
        <Grid item xs={12} sm={12} md={8} >
          <p className="page-description mt-2 mb-px-1 mb-mt-0"><b><i>Hilarine Fernando </i></b>holds a Licentiate Diploma in Speech and Drama (LTCL Teaching) She is a member and an examiner for examinations conducted in 
          Colombo for STSD (The Society of Teachers of Speech and Drama UK). In addition to her extensive theatre teaching experience she currently lectures for Early Childhood Education studies.
          She believes creativity, fun and positivity keeps students enthusiastic towards learning.  
        </p>
        </Grid>

      </Grid>

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className="mb-flex-col-reverse"
      >

        <Grid item xs={12} sm={12} md={8} className="mb-px-2" >
          <p className="page-description mt-2 text-left mb-text-left mb-mt-0"><b><i>Natasha Fernando </i></b>is an educator with nine years of experience, specialising in
working with students of various learning abilities, including those with special
needs. She holds a Bachelor&#39;s Degree in Education and an Advanced Diploma in
Professional Teaching. Natasha is passionate about empowering young learners
to develop confidence and kindness, alongside fostering a keen awareness of
the world around them.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={4} >
          <img src={team4} className="about-img about-img-portrait mb-img-height-auto"  alt="Natasha Fernando"/>
        </Grid>
      </Grid>

    </section>
  )
}

AboutPageTemplate.propTypes = {}

const AboutPage = ({ }) => {
  return (
    <Layout isProgramTypeSelected= {true}>
      <AboutPageTemplate

      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage
